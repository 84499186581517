import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DxGalleryModule, DxPopupModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { CarouselImageModel } from '../../../../../src/app/models/models';
import { S3FileService } from '../../../../../src/app/services/s3file-service';

@Component({
	standalone: true,
	selector: 'app-image-carousel',
	templateUrl: './image-carousel.component.html',
	styleUrls: ['./image-carousel.component.scss'],
	imports: [DxGalleryModule, CommonModule, DxPopupModule],
})
export class ImageCarouselComponent implements OnInit {
	// Input property to accept an array of image URLs
	@Input() images: CarouselImageModel[] = [];

	// Optional inputs for gallery configuration
	@Input() height: string | number = '300px';
	@Input() showIndicator: boolean = true;
	@Input() showNavButtons: boolean = true;
	@Input() slideshowDelay: number = 2000;

	@Output() closeModal = new EventEmitter<void>();

	// Lightbox control variables

	isLightboxOpen: boolean = false;
	currentImage: string | null = null;
	isZoomed: boolean = false;

	// Current index of the image
	currentIndex: number = 0;
	constructor(private s3FileService: S3FileService) {}

	ngOnInit() {
		let promiseList: any[] = this.images.filter((t) => t.imageData == '').map((t, index) => this.fetchImageData(t.s3FileGUID ?? ''));
		Promise.all(promiseList);
	}
	async fetchImageData(fileGUID: string) {
		let response = await this.s3FileService.downloadS3FileData(fileGUID);
		if (response) {
			let index = this.images.findIndex((t) => t.s3FileGUID == fileGUID);
			this.images[index].imageData = `data:${this.s3FileService.getMimeType(response.fileType || '') ?? ''};base64, ${response.fileData}`;
		}
	}
	openLightbox(imageUrl: string) {
		// this.currentImage = imageUrl;
		// this.currentIndex = this.images.indexOf(imageUrl); // Set current index
		// // Set current index
		// this.isLightboxOpen = true;
		// this.isZoomed = false; // Reset zoom state when opening lightbox
	}

	closeLightbox() {
		this.isLightboxOpen = false;
		this.currentImage = null;
		this.isZoomed = false; // Reset zoom state on close
	}

	toggleZoom(event: Event) {
		event.stopPropagation(); // Prevent closing the lightbox
		this.isZoomed = !this.isZoomed; // Toggle zoom state
	}

	nextImage(event: MouseEvent) {
		event.stopPropagation(); // Prevent closing the lightbox
		this.currentIndex = (this.currentIndex + 1) % this.images.length;
		this.currentImage = this.images[this.currentIndex].imageData;
	}

	prevImage(event: MouseEvent) {
		event.stopPropagation(); // Prevent closing the lightbox
		this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
		this.currentImage = this.images[this.currentIndex].imageData;
	}

	closeCarousel() {
		this.closeModal.emit();
	}
}
